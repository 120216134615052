import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable'
import QRCode from "qrcode";
import base64Images from "./base64/images";
import moment from "moment";
import './fonts/Heebo-Black-normal'
import './fonts/Heebo-Regular-normal'
import './fonts/Heebo-Thin-normal'
import './fonts/Heebo-Bold-normal'
import './fonts/Heebo-Light-normal'
import './fonts/Heebo-ExtraLight-normal'
import utils from "../../../helpers/utils";

const reverseString = (string)=> string ? String(string).split('').reverse().join('') : '';


const INVOICE_TYPES = {
    '{עמותה}': {
        associationNumber: 'מספר עמותה:',
        associationName: 'שם העמותה:'
    },
    '{\"חברה פרטית (ח״פ)\"}': {
        associationNumber:  'מספר חברה:',
        associationName:  'שם החברה:'
    },
    '{\"עוסק מורשה (ע״מ)\"}': {
        associationNumber: 'מספר עוסק:',
        associationName: 'שם העוסק:'
    }
}
class InvoiceGenerator {
    constructor({invoice, base64Logo}) {
        this.invoice = invoice;
        this.base64Logo = base64Logo;
        this.doc = new jsPDF({
            unit: 'cm',
            format: 'a4' //21 x 29.7 cm
        });
        this.line = 2;
        this.url = 'https://mysynagogue.net';
        this.createdAtDate = new Date(invoice.createdAt);
        console.log("InvoiceGenerator: this.invoice - ",this.invoice)
    }

    renderStatic(){
        this.doc.setFont("Heebo-Regular");
        this.doc.setR2L(false);
        this.doc.setFontSize(10);
        this.doc.setTextColor('#0D2431');

        this.doc.setFontSize(15);

        this.doc.addImage(base64Images.logo, 'JPEG', 1.4, 0.5, 1, 1);
        this.doc.addImage(base64Images.mySynagogueTxt, 'JPEG', 0.5, 1.7, 3, 0.5);

        if(this.base64Logo) this.doc.addImage(this.base64Logo, 'JPEG', 18.7, 0.5, 1.8, 1.8);

        this.doc.setR2L(true);
    }

    renderTopCenter(){
        this.doc.setFont("Heebo-Bold");
        this.doc.setFontSize(20);
        this.doc.setTextColor('058ED9');
        this.doc.setR2L(true);
        this.doc.text(this.invoice.synagogueName, 10.5, 3, { align: 'center'});

        this.doc.setFontSize(15);
        this.doc.setTextColor('#0D2431');
        this.doc.setFont("Heebo-Bold");
        this.doc.text(this.invoice.synagogueAddress, 10.5, 4, { align: 'center'});
        this.doc.setFontSize(14);
        this.doc.setFont("Heebo-Regular");
        this.doc.text(`טלפון: ${this.invoice.synagoguePhone}`, 10.5, 5, { align: 'center'});

        this.doc.setLineWidth(0.05);
        this.doc.line(0, 6, 21, 6, );



        this.doc.setFontSize(12);
        this.doc.setTextColor('#0D2431');
        this.doc.setFont("Heebo-Bold");
        this.doc.text('לכבוד,', 19, 8, { align: 'right'});
        this.doc.setFont("Heebo-Regular");
        this.doc.text(this.invoice.donorFullName, 19, 8.8, { align: 'right'});
        if(this.invoice.donorEmail){
            this.doc.setR2L(false);
            this.doc.text(this.invoice.donorEmail, 19, 9.4, { align: 'right'});
            this.doc.setR2L(true);
        }


        this.doc.setFont("Heebo-Bold");
        this.doc.text('עבור:', 19, 12, { align: 'right'});
        this.doc.setFont("Heebo-Regular");
        this.doc.text(this.invoice.donorFullName, 19, 12.5, { align: 'right'});


        this.doc.setFontSize(9);
        this.doc.setFont("Heebo-Light");
        this.doc.text('תאריך:', 6, 12, { align: 'right'});
        this.doc.setR2L(false);
        this.doc.text(moment(this.createdAtDate).format("DD.MM.YYYY"), 4, 12, { align: 'right'});
        this.doc.setR2L(true);
        if(this.invoice.associationNumber){
            this.doc.setR2L(true);
            this.doc.text(INVOICE_TYPES[this.invoice.type].associationNumber, 6, 12.5, { align: 'right'});
            this.doc.setR2L(false);
            this.doc.text(this.invoice.associationNumber,4, 12.5, { align: 'right'});
            this.doc.setR2L(true);
        }
        if(this.invoice.associationName){
            this.doc.setR2L(true);
            this.doc.text(INVOICE_TYPES[this.invoice.type].associationName, 6, 13, { align: 'right'});
            this.doc.text(this.invoice.associationName,4, 13, { align: 'right'});
            this.doc.setR2L(true);
        }

        this.doc.setFontSize(12);
        this.doc.setR2L(true);
        this.doc.setFont("Heebo-Bold");
        this.doc.text(`תרומה: ${this.invoice.id}`, 10.5, 14, { align: 'center'});
        this.doc.text('מקור - מסמך ממוחשב', 4, 14, { align: 'center'});

        autoTable(this.doc, {
            head: [['סוג תשלום', 'תשלומים', 'פרטים נוספים', 'סוג מטבע', 'סכום'].reverse()],
            styles: {
                font: 'Heebo-Bold',
                halign: 'center'

            },
            margin: { top: 15, bottom: 5 },

            body: [
                [this.invoice.paymentType, '1', this.invoice.description, 'שקל', reverseString(this.invoice.amount)].reverse(),
            ]
        })


        this.doc.setFontSize(12);
        this.doc.setR2L(true);
        this.doc.setFont("Heebo-Bold");
        this.doc.text(`סה״כ לתשלום: ${this.invoice.amount} ש״ח`, 3.8, 17.5, { align: 'center'});

        if(this.invoice.chapter46){
            this.doc.setFont("Heebo-Regular");
            this.doc.text('למוסד אישור מס הכנסה לעניין תרומות לפי סעיף 46 לפקודה', 19, 19, { align: 'right'});
        }


        this.doc.setFont("Heebo-Thin");
        this.doc.text(`חתימה דיגיטלית:`, 3, 19, { align: 'center'});
        this.doc.addImage(this.invoice.signature, 'JPEG', 2, 19.3, 2, 2);

        this.doc.setFontSize(9);
        this.doc.text(`הופק ע״י:`, 4.3, 23);
        this.doc.setTextColor('058ED9');
        this.doc.setR2L(false);
        this.doc.textWithLink('mysynagogue.net', 1.7, 23, {url: this.url});
        this.doc.setTextColor('#0D2431');
        this.doc.setR2L(true);




        this.doc.setFont("Heebo-Bold");
        this.doc.text(`כתובת: ${this.invoice.synagogueAddress}`, 19, 29, { align: 'right'});
        this.doc.setR2L(false);
        this.doc.text(`Email: ${this.invoice.synagogueEmail}`, 2, 29, );
        this.doc.setLineWidth(0.02);
        this.doc.line(1, 29.5, 20, 29.5, );



    }

    async init(){
        this.qrCodeBase64 = await QRCode.toDataURL(this.url, {type: "png"});
    }

    getFileName() {
        return `mysynagogue_invoice_${this.invoice.id}_${moment(this.createdAtDate).format("DD-MM-YYYY")}`;
    }

    _make(){
        this.renderStatic();
        this.renderTopCenter();
    }

    makePDF(){
        this._make()
        this.doc.save(`${this.getFileName()}.pdf`);

    }

    async sharePDF(){
        let text = '';
        text += `קבלה עבור: `;
        text += this.invoice.donorFullName;
        text += '%0A';
        text += `סכום: `;
        text += this.invoice.amount;
        text += '%0A';
        text += `מספר קבלה: `;
        text += this.invoice.id;
        text += '%0A';


        text+= `https://mysynagogue.net/${utils.getSynagogueName()}`;


        if(!navigator.share){
            window.open( `https://api.whatsapp.com/send?text=${text}`, '_blank');
            return;
        }
        this._make()
        const buffer = this.doc.output('arraybuffer')

        const pdf = new File([buffer], `${this.getFileName()}.pdf`, { type: "application/pdf" });
        const files = [pdf];

        const data = {
            url: utils.getAppUrl(),
            text,
            title: `קבלה - ${this.invoice.id} - ${this.invoice.synagogueName}`,
            files
        }

        // Share PDF file if supported.
        if (navigator.canShare({ files })) await navigator.share(data);
    }
}

export default InvoiceGenerator;