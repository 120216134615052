import React, {useEffect, useState} from 'react';

import './NewsAndUpdatesSlider.scss';
import TextViewer from "../../../../helpers/textViewer";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {ReactComponent as increase_size_logo} from "../../icons/increase-size.svg";


import { Autoplay, Pagination, Navigation } from "swiper";
import {Link} from "react-router-dom";
import utils from "../../../../helpers/utils";
import SingletoneStoreClass from "../../../../Store/Store";
import config from "../../../../config";
import ReactPlayer from 'react-player/youtube'

const store = SingletoneStoreClass.getInstance();

const NewsAndUpdatesSlider = (props)=>{

    const {loading, location, updates, externalFormDetails, externalFormUrl, prayersHebrewDatesEvents} = props;

    const [openAttachment, setOpenAttachment] = useState(false);
    const [attachmentToPreview, setAttachmentToPreview] = useState(null);

    document.addEventListener("mousedown", (event) => {
        if(event.target.className && typeof event.target.className === "string" && !event.target.className.includes("attachment-modal")){
            setOpenAttachment(false);
            setAttachmentToPreview(null);
        }
    });

    document.addEventListener("mousewheel", (event) => {
        if(event?.wheelDeltaY < 0){
            //Scroll down
            setOpenAttachment(false);
            setAttachmentToPreview(null);
        }
    });

    if(loading){
        return null;
        return  <div className={'news-and-update-container'}>
            <TextViewer text={"loading"} addedString={'...'}/>
        </div>
    }

    const slides = [];

    const weeklySpecialEvents = store.getWeeklySpecialEventsV2(new Date(), location, 5);

    const onAttachmentClicked = (attachmentUrl)=>{
        setOpenAttachment(true);
        setAttachmentToPreview(attachmentUrl)
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const renderAttachment = (attachmentUrl, className)=>{
        if(attachmentUrl.includes('pdf')){
            return (<div className={'attachment-container'}>
                <div className={'swipe-item'}><div className={'attachment-item'} ><iframe src={attachmentUrl} onClick={()=>onAttachmentClicked(attachmentUrl)}/></div></div>
        </div>)}
        return (<div className={`attachment-container ${className ? className : ''}`}>
            <div className={'swipe-item'}><div className={'attachment-item'}><img src={attachmentUrl} alt="avatar" onClick={()=>onAttachmentClicked(attachmentUrl)}/></div></div>
        </div>)
    }

    utils.filterUpdates({updates}).forEach((update)=>{
        const { text, title, attachmentUrl, youtubeUrl } = update;
        slides.push(
            <SwiperSlide>
                <div className={'swipe-item'}>{<TextViewer text={title || "gabaais_updates"}/>}</div>
                {text ? <div className={'swipe-item regular-text'}>{text}</div> : null}
                {attachmentUrl ? renderAttachment(attachmentUrl): null}
                {youtubeUrl ?
                    <ReactPlayer
                        className={'player-container-main-slider'}
                        url={youtubeUrl}
                        muted={true}
                    />
                    : null}


            </SwiperSlide>);
    })

    if(externalFormUrl){
        slides.push(
            <SwiperSlide>
                <div className={'swipe-item'}>
                    <div className={'swipe-item'}> <TextViewer text={"link_to_external_form_url"}/></div>
                    <Link to={`/${utils.getSynagogueName()}/external-url`}>
                        <div className={'swipe-item-link'}>{<TextViewer text={'click_to_fill_out_the_online_form'} addChar={externalFormDetails ? ':' : ''} addedString={externalFormDetails ? `${externalFormDetails}` : ''}/>}</div>
                    </Link>
                </div>
            </SwiperSlide>
        );
    }

    if(weeklySpecialEvents && weeklySpecialEvents.length > 0 && store.getSynagogueName() !== 'AshreyHaam' && store.getSynagogueName() !== 'Shaoli'){
        slides.push(
            <SwiperSlide>
                <div className={'swipe-item'}> <TextViewer text={"important_dates"}/></div>
                <div className={'swipe-item'}>{weeklySpecialEvents.map(event=><div>
                    {event.hebrewDate} - {event.desc}
                </div>)}</div>
            </SwiperSlide>);
    }


    const renderAttachmentModal = ()=> {
        if(!openAttachment) return null;
        if(!attachmentToPreview) return null;
        if(attachmentToPreview.includes('pdf')){
            return  <div className={'attachment-modal-container'}><iframe className={'attachment-modal'} src={attachmentToPreview}/></div>
        }else{
            return <div className={'attachment-modal-container'}><img className={'attachment-modal'} src={attachmentToPreview} alt="avatar"/></div>

        }

    }

    if(prayersHebrewDatesEvents.length){
        const getLastSunday = ()=>{
            const currentDate = new Date();
            const currentDay = currentDate.getDay();
            const offsetToLastSunday = (currentDay + 7) % 7;
            const lastSundayDate = new Date(currentDate);
            lastSundayDate.setDate(currentDate.getDate() - offsetToLastSunday);
            return lastSundayDate;
        }

        const memorialPrayersHebrewDatesEvents = [];
        const birthdayPrayersHebrewDatesEvents = [];
        const lastSunday = getLastSunday();
        const hebrewDatesNextWeekMap = {};
        for(let i=0; i<=7; i++){
            const { monthName, dayOfMonth } = store.getHebrewDayAndMouthAndYear({date: lastSunday, location});
            hebrewDatesNextWeekMap[`${config.englishMonthToHebrew[monthName]}_${config.hebrewNumToDay[dayOfMonth]}`] = true;
            lastSunday.setDate(lastSunday.getDate()+1);
        }


        prayersHebrewDatesEvents.forEach(event=>{
            if(['יארצייט','יורצייט'].includes(event.reason)){
                if(hebrewDatesNextWeekMap[`${event.hebrewMonth}_${event.hebrewDay}`]){
                    memorialPrayersHebrewDatesEvents.push(event)
                }
            }
            if(['יום הולדת'].includes(event.reason)){
                if(hebrewDatesNextWeekMap[`${event.hebrewMonth}_${event.hebrewDay}`]){
                    birthdayPrayersHebrewDatesEvents.push(event)
                }
            }
        })

        console.log("---memorialPrayersHebrewDatesEvents:",memorialPrayersHebrewDatesEvents);
        console.log("---birthdayPrayersHebrewDatesEvents:",birthdayPrayersHebrewDatesEvents);


        const getRandomGifUrl = ()=>{
            const gifUrls = [
                'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg6FzbInHJ8esvwKr9RceU_TrThQqqPrXrI8J-Xo-1pFmWUK75ApIw965oBvnvlhL1DPmjnhzWjG1yqN_8UA-a-iGCEz4cFxEvpIrgn2dshW6F4JAFpLLueojQblkm7T35o7BW6TSsnBuM/s1600/normal_5c875ce053a0e.gif',
                'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhferHopLkSlQcAeHA4SUIslLYcFh2aOcZJU-jmGhv2p1Y1QAqcBi6wtOq1lozONpJSzaj5NYW4V2F-GeK6OCS2tNQDa1cwnPX8y7coS5ubH-mho2YBdAliuNAmwnkLG6juHv9qDmUig1c/s1600/normal_5c875cecb20fd.gif',
                'https://3.bp.blogspot.com/-kIQBb5PoLPE/XE2FbY8mizI/AAAAAAAAdh8/CUXPuDjM-FU9cjfBsPR_61Ryd3_ZqdFBgCLcBGAs/s1600/4473-20120820101107982.gif',
                'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjinP0ZNTUe2G1yubxkoWnc7e_XM1ozqJpkCVaOn9aBy-wlUWiTL2vR13_YHhe7eS2I2l8RK0sHCMI5EN7h92fG3CPvpSLir1dJQPTNiaP1pRFOiWt5ivtnTqCp1M5awIDVPnjm2aWlVpI/s1600/%25D7%2599%25D7%2595%25D7%259E%25D7%2595%25D7%259C%25D7%2593%25D7%25AA+%25D7%25A9%25D7%259E%25D7%25976.gif',
                'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi5O1P-u8y5eHVukR2r9GGdWhja46J7cLAOBrV-AadLUoYyHJxICE-0Jpr5CxVVJvDCYFh4Nd93kw3n_pAbvU_3Zkdx_CD0XUIYc3tCwfCSh_tY_zQ7N9gm7nkSqfBKzbJvKObQ109FKQI/s1600/normal_5c875d0c8fe84.gif',
                'https://narkis2016.files.wordpress.com/2016/11/8fe_b3a_3d2_33f_fcb_happy_birthday28.gif',
            ]
            return gifUrls[Math.floor(Math.random() * gifUrls.length)];
        }



        if(store.getSynagogueName() !== 'rosh-tzurim' ){
            birthdayPrayersHebrewDatesEvents.forEach(birthdayPrayersHebrewDatesEvent=>{
                const {name, family, hebrewDay, hebrewMonth} = birthdayPrayersHebrewDatesEvent;
                slides.push(
                    <SwiperSlide>
                        <div className={'birthday-item-container'}>
                            <div className={'swipe-item'}>{<TextViewer text={"birthday"}/>}</div>
                            {<div className={'swipe-item regular-text'}>{`${name} ${family ? family : ''}`}</div>}
                            {<div className={'swipe-item regular-text'}>{`${hebrewDay} ${hebrewMonth}`}</div>}
                            {renderAttachment(getRandomGifUrl(), 'birthday-gif')}
                        </div>


                    </SwiperSlide>);
            })
        }



        //todo: handle יורצייט ?


    }

    if(slides.length === 0) return null;



    return <div className={'news-and-update-container'}>

        <div className={'news-and-update-title'}>
            <TextViewer text={"nearest_events_and_updates"}/>
        </div>

        <div className={'news-and-update-swipe-container'}>
            <Swiper
                speed={3000}
                autoHeight={true}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="synagogue-swipe-container"
            >
                {slides.map(s=>s)}
            </Swiper>
        </div>

        {renderAttachmentModal()}


    </div>
}

export default NewsAndUpdatesSlider;