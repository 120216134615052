import {jsPDF} from "jspdf";
import QRCode from "qrcode";
import base64Images from "./base64/images";
import './fonts/Heebo-Black-normal'
import './fonts/Heebo-Regular-normal'
import './fonts/Heebo-Thin-normal'
import './fonts/Heebo-Bold-normal'
import './fonts/Heebo-Light-normal'
import './fonts/Heebo-ExtraLight-normal'
import PRAYER_CONFIGS from "../Management/PrayersDetails/config/prayerConfig";
import translateText from "../../../helpers/translateText";
import {analyticsAddLogEvent} from "../../../firebase/firebase";
import utils from "../../../helpers/utils";

class PrayerCardGenerator {
    constructor({prayer, base64Logo}) {
        this.prayer = prayer;
        this.base64Logo = base64Logo;
        this.doc = new jsPDF({
            unit: 'cm',
            orientation: 'landscape',

            format: 'a4' //21 x 29.7 cm
        });
        this.line = 2;
        this.url = `${window.location.origin}/${window.location.pathname.split("/")[1]}`;


        analyticsAddLogEvent({eventName: `generate_card_prayer_pdf_event`, params: { name: utils.getSynagogueName()}});


        // console.log("PrayerCardGenerator: this.prayer - ",this.prayer)
    }

    renderStatic(){
        this.doc.setFont("Heebo-Regular");
        this.doc.setR2L(false);
        this.doc.setFontSize(10);
        this.doc.setTextColor('#0D2431');

        this.doc.setFontSize(15);

        this.doc.addImage(base64Images.logo, 'JPEG', 1.4, 0.5, 1, 1);
        this.doc.addImage(base64Images.mySynagogueTxt, 'JPEG', 0.5, 1.7, 3, 0.5);

        if(this.base64Logo) this.doc.addImage(this.base64Logo, 'JPEG', 27.3, 0.5, 1.8, 1.8);
        if(this.prayer.picture) this.doc.addImage(this.prayer.picture, 'JPEG', 7, 2.3, 3, 3);


        this.doc.setFont("Heebo-Regular");
        this.doc.setR2L(false);
        this.doc.setFontSize(10);
        this.doc.setTextColor('058ED9');
        this.doc.setR2L(true);
        this.doc.text(translateText({text: 'barcode_scan'}), 2.2, 25, { align: 'center'});
        this.doc.setR2L(false);

        this.doc.addImage(this.qrCodeBase64, 'JPEG', 0.7, 25.2, 3, 3);

        this.doc.textWithLink(this.url, 1, 29, {url: this.url});

        this.doc.setR2L(true);


    }

    renderCell({content}){
        return { content: content || '-', styles: { font: 'Heebo-Black', halign: 'right' } }
    }

    renderTopCenter(){
        let y = 7;
        let x = 27;
        const fontSize = 14;

        this.doc.setFont("Heebo-Bold");
        this.doc.setFontSize(20);
        this.doc.setTextColor('058ED9');
        this.doc.setR2L(true);
        this.doc.text(`${this.prayer.name} ${this.prayer.family}`, 17, 3, { align: 'center'});

        const newLine = ()=>{
            y = y + 0.7;
        }


        Object.keys(PRAYER_CONFIGS).forEach(key=>{
            const {keyName, text_view_name, base64Mode, text_view_button_name, showOnPrayerCard } = PRAYER_CONFIGS[key];
            if(showOnPrayerCard && this.prayer[keyName]){
                newLine();
                this.doc.setFont("Heebo-Bold");
                this.doc.setFontSize(fontSize);
                this.doc.setTextColor('058ED9');
                this.doc.setR2L(true);
                this.doc.text(translateText({text: text_view_name === 'email' ? 'אימייל' : text_view_name, addChar: ['isMember'].includes(keyName) ? '' : ':'} ), x, y, { align: 'right'});

                this.doc.setFont("Heebo-Regular");
                this.doc.setFontSize(fontSize);
                this.doc.setTextColor('058ED9');
                if(keyName === 'email' || keyName === 'phoneNumber'){
                    this.doc.setR2L(false);
                }else{
                    this.doc.setR2L(true);
                }
                if(typeof this.prayer[keyName] === 'string') this.doc.text(this.prayer[keyName], x - 6, y, { align: 'right'});
                if(typeof this.prayer[keyName] === 'boolean') this.doc.text(this.prayer[keyName] ? 'כן' : 'לא', x - 6, y, { align: 'right'});
            }
        });

        if(this.prayer.birthdayHebrewDay && this.prayer.birthdayHebrewMonth) {
            newLine();
            this.doc.setFont("Heebo-Bold");
            this.doc.setFontSize(fontSize);
            this.doc.setTextColor('058ED9');
            this.doc.setR2L(true);
            this.doc.text('יום הולדת:', x, y, {align: 'right'});

            this.doc.setFont("Heebo-Regular");
            this.doc.setFontSize(fontSize);
            this.doc.setTextColor('058ED9');
            this.doc.text(`${this.prayer.birthdayHebrewDay} ${this.prayer.birthdayHebrewMonth}`, x - 6, y, {align: 'right'});
        }

        newLine();
        y= 7;
        x = 15;

        if(this.prayer.importantDates && Array.isArray(this.prayer.importantDates) && this.prayer.importantDates.length > 0){
            newLine();
            this.doc.setFont("Heebo-Bold");
            this.doc.setFontSize(fontSize);
            this.doc.setTextColor('058ED9');
            this.doc.setR2L(true);
            this.doc.text('תאריכים חשובים:', x, y, {align: 'right'});
            this.prayer.importantDates.forEach(importantDates=>{
                newLine();
                this.doc.setFont("Heebo-Regular");
                this.doc.setFontSize(fontSize);
                this.doc.setTextColor('058ED9');
                this.doc.text(`- ${importantDates.reason}: ${importantDates.relation} - ${importantDates.name}, ${importantDates.hebrewDay} ${importantDates.hebrewMonth}`, x - 0.5, y, {align: 'right'});
            })
        }

        if(this.prayer.familyMembers && Array.isArray(this.prayer.familyMembers) && this.prayer.familyMembers.length > 0){
            newLine();
            this.doc.setFont("Heebo-Bold");
            this.doc.setFontSize(fontSize);
            this.doc.setTextColor('058ED9');
            this.doc.setR2L(true);
            this.doc.text('בני משפחה:', x, y, {align: 'right'});
            this.prayer.familyMembers.forEach(familyMember=>{
                newLine();
                this.doc.setFont("Heebo-Regular");
                this.doc.setFontSize(fontSize);
                this.doc.setTextColor('058ED9');
                this.doc.text(`- ${familyMember.relation}: ${familyMember.name}, ${' יום הולדת, '} ${familyMember.hebrewDay} ${familyMember.hebrewMonth}`, x - 0.5, y, {align: 'right'});
            })
        }
    }

    async init(){
        this.qrCodeBase64 = await QRCode.toDataURL(this.url, {type: "png"});
    }

    getFileName() {
        return `mysynagogue_prayer_${this.prayer.name}_${this.prayer.family}`;
    }

    _make(){
        this.renderStatic();
        this.renderTopCenter();
    }

    makePDF(){
        this._make()
        this.doc.save(`${this.getFileName()}.pdf`);

    }

}

export default PrayerCardGenerator;