import React, {useEffect, useState} from 'react';
import SingletoneStoreClass from "../../../../Store/Store";
import {useHistory} from "react-router-dom";
import utils from "../../../../helpers/utils";
const store = SingletoneStoreClass.getInstance();


const PaymentAcceptable = (props)=>{

    const history = useHistory();


    useEffect(() => {

        const fetchData = async ()=>{
            await store.setSynagoguePaymentDisableFalse();
            history.push(`/${utils.getSynagogueName()}/edit`);

        }

        fetchData();

    }, []);



    return <div>
        PaymentAcceptable
    </div>
}

export default PaymentAcceptable;