import './fonts/Heebo-Black-normal'
import './fonts/Heebo-Regular-normal'
import './fonts/Heebo-Thin-normal'
import './fonts/Heebo-Bold-normal'
import './fonts/Heebo-Light-normal'
import './fonts/Heebo-ExtraLight-normal'
import QRCode from 'qrcode'

import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable'

import translateText from "../../../helpers/translateText";
import base64Images from "./base64/images";
import TextViewer from "../../../helpers/textViewer";
import utils from "../../../helpers/utils";
import moment from "moment";
import SingletoneStoreClass from "../../../Store/Store";
import {analyticsAddLogEvent} from "../../../firebase/firebase";

const store = SingletoneStoreClass.getInstance();

const DAY_NUM_TO_SHORT_DISPLAY = {
    0: 'sunday_short',
    1: 'monday_short',
    2: 'tuesday_short',
    3: 'wednesday_short',
    4: 'thursday_short',
    5: 'friday_short',
    6: 'saturday_short'
}

const reverseString = (string)=> string ? String(string).split('').reverse().join('') : ''


const calcDept = (user)=>{
    let amount = 0;
    if(Array.isArray(user.aliyot)){
        user.aliyot.forEach(aliyah=>{
            if(aliyah?.isPayed === true) return;
            if(aliyah.aliyahPayment) amount+= Number(aliyah.aliyahPayment);
            if(aliyah.aliyahSubPayment) amount-= Number(aliyah.aliyahSubPayment);
        })
    }
    if(Array.isArray(user.donations)){
        user.donations.forEach(donation=>{
            if(donation?.isPayed === true) return;
            if(donation.donationTotalPayment) amount+= Number(donation.donationTotalPayment);
            if(donation.donationSubPayment) amount-= Number(donation.donationSubPayment);
        })
    }
    return amount;
}

const TABLE_MODES = {
    prayers: {
        tableColumns: [
            { head: 'first_name', body: 'name' },
            { head: 'last_name', body: 'family' },
            { head: 'father_name', body: 'fatherName' },
            { head: 'mother_name', body: 'motherName' },
            { head: 'alia_name', body: 'aliaName' },
            { head: 'parashat_bar_mizva', body: 'parashatBarMizva' },
            { head: 'type', body: 'type' },
            { head: 'phoneNumber', body: 'phoneNumber', render: (phoneNumber, item, cveMode=false)=> cveMode ? phoneNumber : reverseString(phoneNumber)},
            { head: 'total_dept', body: 'dept', render: (phoneNumber, item, cveMode=false)=> {
                const dept = calcDept(item);
                return cveMode ? dept : reverseString(dept)
            }}
        ]
    },
    aliyot: {
        tableColumns: [
            { head: 'first_name', body: 'user', render: (user)=> user.name },
            { head: 'last_name', body: 'user', render: (user)=> user.family },
            { head: 'when_last_aliya', body: 'aliyahTime', render: (aliyahTime)=> utils.calcTimeDiff({stringDate: aliyahTime, TextViewer}) },
            { head: 'gregorian_date', body: 'aliyahTime', render: (aliyahTime)=> reverseString(moment(new Date(aliyahTime)).format("DD.MM.YYYY")) },
            { head: 'hebrew_date', body: 'aliyahTime', render: (aliyahTime)=> store.toHebrewDate({date: new Date(aliyahTime)}) },
            { head: 'payment', body: 'aliyahPayment', render: (aliyahPayment)=> reverseString(aliyahPayment) },
            { head: 'sub_payment', body: 'aliyahSubPayment', render: (aliyahSubPayment)=> reverseString(aliyahSubPayment) },
            { head: 'is_payed', body: 'isPayed', render: (isPayed, item)=> isPayed ? 'כן' : (item.aliyahPayment ? 'לא' : '')},
            { head: 'aliyah_reason', body: 'aliyahReason', render: (aliyahReason)=> aliyahReason },
        ]
    },
    importantDates: {
        tableColumns: [
            { head: 'first_name', body: 'user', render: (importantDate, item)=> item.user.name },
            { head: 'last_name', body: 'user', render: (importantDate, item)=> item.user.family },
            { head: 'for_who', body: 'relation', render: (importantDate, item)=> `${item.relation} - ${item.name || ''}`  },
            { head: 'what_is_the_event', body: 'reason' },
            { head: 'hebrew_date', body: 'reason', render: (importantDate, item)=> (item.hebrewDay && item.hebrewMonth) ? `${item.hebrewDay} ${item.hebrewMonth}` : '' },
            { head: 'parasha', body: 'parasha', render: (importantDate, item)=> item.parasha },
        ]
    },
    weeklyReportUsers: {
        tableColumns: [
            { head: 'first_name', body: 'name' },
            { head: 'last_name', body: 'family' },
            { head: 'type', body: 'type' },
            { head: 'alia_name', body: 'user', render: (aliyot, user)=> user.aliaName },
            { head: 'aliyah_reason', body: 'reason' },
            { head: 'when_last_aliya', body: 'aliyot', render: (aliyot)=> (aliyot && aliyot.length > 0) ? utils.calcTimeDiff({stringDate: new Date(Math.max(...aliyot.map(aliya => new Date(aliya.aliyahTime)))), TextViewer})  : '' },
            { head: 'latest_aliyah_date', body: 'aliyot', render: (aliyot)=> (aliyot && aliyot.length > 0) ? reverseString(moment(new Date(Math.max(...aliyot.map(aliya => new Date(aliya.aliyahTime))))).format("DD.MM.YYYY")) : '' },
        ]
    },
    donations: {
        tableColumns: [
            { head: 'first_name', body: 'user', render: (user)=> user.name },
            { head: 'last_name', body: 'user', render: (user)=> user.family },
            { head: 'gregorian_date', body: 'donationTime', render: (donationTime)=> reverseString(moment(new Date(donationTime)).format("DD.MM.YYYY")) },
            { head: 'hebrew_date', body: 'donationTime', render: (donationTime)=> store.toHebrewDate({date: new Date(donationTime)}) },
            { head: 'payment', body: 'donationTotalPayment', render: (donationTotalPayment)=> reverseString(donationTotalPayment) },
            { head: 'sub_payment', body: 'donationSubPayment', render: (donationSubPayment)=> reverseString(donationSubPayment) },
            { head: 'is_payed', body: 'isPayed', render: (isPayed, item)=> isPayed ? 'כן' : (item.aliyahPayment ? 'לא' : '')},
            { head: 'donation_reason', body: 'donationReason', render: (donationReason)=> donationReason },
        ]
    },
    timesOfTheDay: {
        tableColumns: [
            { head: 'gregorian_date', body: 'date', render: (date, item, cveMode=false)=> cveMode ? moment(date).format("DD.MM.YYYY") : reverseString(moment(date).format("DD.MM.YYYY")) },
            { head: 'hebrew_date', body: 'date', render: (date)=> store.toHebrewDate({date}) },
            { head: 'day', body: 'date', render: (date)=> reverseString(TextViewer({text: DAY_NUM_TO_SHORT_DISPLAY[date.getDay()], returnString: true})) },
            { head: 'alot_hashacher', body: 'alot_hashacher', render: (alot_hashacher, item, cveMode=false)=> cveMode ? moment(alot_hashacher).format("HH:mm") : reverseString(moment(alot_hashacher).format("HH:mm")) },
            { head: 'sunrise', body: 'sunrise', render: (sunrise, item, cveMode=false)=> cveMode ? moment(sunrise).format("HH:mm") : reverseString(moment(sunrise).format("HH:mm")) },
            { head: 'sof_zman_shma', body: 'sof_zman_shma', render: (sof_zman_shma, item, cveMode=false)=> cveMode ? moment(sof_zman_shma).format("HH:mm") : reverseString(moment(sof_zman_shma).format("HH:mm")) },
            { head: 'plag_hamincha', body: 'plag_hamincha', render: (plag_hamincha, item, cveMode=false)=> cveMode ? moment(plag_hamincha).format("HH:mm") : reverseString(moment(plag_hamincha).format("HH:mm")) },
            { head: 'sunset', body: 'sunset', render: (sunset, item, cveMode=false)=> cveMode ? moment(sunset).format("HH:mm") : reverseString(moment(sunset).format("HH:mm")) },
            { head: 'tzeit', body: 'tzeit', render: (tzeit, item, cveMode=false)=> cveMode ? moment(tzeit).format("HH:mm") : reverseString(moment(tzeit).format("HH:mm")) }
        ]
    },
    tfilotAndLessons: {
        tableColumns: [
            { head: 'gregorian_date', body: 'date', render: (date, item, cveMode=false)=> cveMode ? moment(date).format("DD.MM.YYYY") : reverseString(moment(date).format("DD.MM.YYYY")) },
            { head: 'hebrew_date', body: 'date', render: (date)=> store.toHebrewDate({date}) },
            { head: 'day', body: 'date', render: (date)=> reverseString(TextViewer({text: DAY_NUM_TO_SHORT_DISPLAY[date.getDay()], returnString: true})) },
            { head: 'description', body: 'description',  render: (date, item)=> item.description }
        ]
    },
}

class PDFGenerator{
    constructor({base64Logo, synagogueName, title, type='report', items, exportType}) {
        // console.log("PDFGenerator: ",{base64Logo, synagogueName, title, type, items, exportType})
        this.base64Logo = base64Logo;
        this.synagogueName = synagogueName
        this.type = type
        this.url = `${window.location.origin}/${window.location.pathname.split("/")[1]}`;
        this.qrCodeBase64 = null;
        this.doc = new jsPDF({
            unit: 'cm',
            format: 'a4' //21 x 29.7 cm
        });
        this.line = 2;
        this.title = title;
        this.items = items;
        this.exportType = exportType;
        // console.log("----this.items=",items);
        analyticsAddLogEvent({eventName: `generate_${this.type}_${this.exportType}_event`, params: { name: utils.getSynagogueName()}});
    }

    renderStatic(){
        this.doc.setFont("Heebo-Regular");
        this.doc.setR2L(false);
        this.doc.setFontSize(10);
        this.doc.setTextColor('058ED9');
        this.doc.setR2L(true);
        this.doc.text(translateText({text: 'barcode_scan'}), 2.2, 24.9, { align: 'center'});
        this.doc.setR2L(false);

        this.doc.addImage(this.qrCodeBase64, 'JPEG', 0.7, 25, 3, 3);

        this.doc.textWithLink(this.url, 1, 29, {url: this.url});

        this.doc.setTextColor('#0D2431');

        this.doc.setFontSize(15);

        this.doc.addImage(base64Images.logo, 'JPEG', 1.4, 0.5, 1, 1);
        this.doc.addImage(base64Images.mySynagogueTxt, 'JPEG', 0.5, 1.7, 3, 0.5);

        if(this.base64Logo) this.doc.addImage(this.base64Logo, 'JPEG', 18.7, 0.5, 1.8, 1.8);

        this.doc.setR2L(true);
    }

    async init(){
        this.qrCodeBase64 = await QRCode.toDataURL(`${window.location.origin}/${window.location.pathname.split("/")[1]}`, {type: "png"});
    }

    setTitle(){
        this.doc.setR2L(true);
        this.doc.setFont("Heebo-Black");
        this.doc.text(this.title, 10.5, this.line, 'center');
        this.line++;
    }

    makeCSV(){
        console.log("making CSV...");
        // Empty array for storing the values
        const csvRows = [];

        // Headers is basically a keys of an
        // object which is id, name, and
        // profession
        const headers = TABLE_MODES[this.type].tableColumns.map(tableColumn=>TextViewer({text: tableColumn.head, returnString: true}));

        // As for making csv format, headers
        // must be separated by comma and
        // pushing it into array
        csvRows.push(headers);

        this.items.forEach(item=>{
            // Pushing Object values into array
            // with comma separation
            const values = [];
            TABLE_MODES[this.type].tableColumns.map(tableColumn=>{
                values.push(this.renderCell({content: tableColumn.render ? tableColumn.render(item[tableColumn.body], item, true) : item[tableColumn.body]}).content);
            })
            csvRows.push(values)
        })


        let csvContent = 'data:text/csv;charset=utf-8,' +"\uFEFF"
            + csvRows.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        console.log(csvContent)
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${this.getFileName()}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".

    }

    renderCell({content}){
        return { content: content || '-', styles: { font: 'Heebo-Black', halign: 'right' } }
    }

    getFileName() {
        return `${new Date().toLocaleDateString()}_${this.synagogueName}_${this.type}`.split(' ').join('_');
    }

    makePDF(){
        if(this.exportType === 'csv'){
            this.makeCSV();
            return;
        }
        this.doc.setR2L(true);
        this.setTitle();



        autoTable(this.doc, {
            didDrawPage: this.renderStatic.bind(this),
            head: [
                TABLE_MODES[this.type].tableColumns.map(tableColumn=>this.renderCell({content: TextViewer({text: tableColumn.head, returnString: true})})).reverse()
            ],
            margin: { top: 3, bottom: 5 },
            body: [
                ...this.items.map(item=>{
                    return TABLE_MODES[this.type].tableColumns.map(tableColumn=>{
                         return this.renderCell({content: tableColumn.render ? tableColumn.render(item[tableColumn.body], item) : item[tableColumn.body]});
                    }).reverse()
                })
            ]
        })

        this.doc.save(`${this.getFileName()}.pdf`);
    }




}

export default PDFGenerator;